import { render, staticRenderFns } from "./LecturerEditLayer.vue?vue&type=template&id=638624ce&scoped=true&"
import script from "./LecturerEditLayer.vue?vue&type=script&lang=js&"
export * from "./LecturerEditLayer.vue?vue&type=script&lang=js&"
import style0 from "./LecturerEditLayer.vue?vue&type=style&index=0&id=638624ce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "638624ce",
  null
  
)

export default component.exports